<template>
  <div v-if="respData">
    <b-row v-if="$ability.can('read', this.$route.meta.resource)" class="match-height">
      <b-col md="12">
        <b-card title="System">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Dev :
            </b-card-text>
            <b-form-checkbox :checked="respData['dev'] != null ? respData['dev'] : false" class="custom-control-primary"
              name="check-button" switch @change="switchChange('dev', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["dev"] != null
      ? respData["dev"] == true
        ? "เปิดระบบพัฒนา"
        : "ปิดระบบพัฒนา"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Maintenance :
            </b-card-text>
            <b-form-checkbox :checked="respData['maintenance'] != null
      ? respData['maintenance']
      : false
    " class="custom-control-primary" name="check-button" switch @change="switchChange('maintenance', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["maintenance"] != null
      ? respData["maintenance"] == true
        ? "เปิดการปรับปรุงระบบ"
        : "ปิดการปรับปรุงระบบ"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <!-- Pay Solution -->
      <b-col md="6">
        <b-card title="PaySolution">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Status :
            </b-card-text>
            <b-form-checkbox :checked="respData['payment']['paySolution'] != null
      ? respData['payment']['paySolution']['status']
      : false
    " class="custom-control-primary" name="check-button" switch
              @change="switchChange('payment.paySolution.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData['payment']["paySolution"] != null
      ? respData['payment']["paySolution"]["status"] == true
        ? "เปิด (Pay Solution)"
        : "ปิด (Pay Solution)"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>

          </b-row>
          <div v-if="respData['payment']['paySolution']['status']">
            <!-- paySolutionSecretKey -->
            <b-form-group label-cols="2" label-cols-lg="2" label="Secret Key :"
              label-for="input-lg-paySolution-secret-key">
              <b-form-input id="input-lg-paySolution-secret-key" v-model="paySolutionSecretKey"
                placeholder="Secret Key" />
            </b-form-group>
            <!-- paySolutionApiKey -->
            <b-form-group label-cols="2" label-cols-lg="2" label="apiKey :" label-for="input-lg-paySolution-api-key">
              <b-form-input id="input-lg-paySolution-api-key" v-model="paySolutionApiKey" placeholder="apiKey" />
            </b-form-group>
            <!-- paySolutionMerchantId -->
            <b-form-group label-cols="2" label-cols-lg="2" label="merchantId :"
              label-for="input-lg-paySolution-merchantId">
              <b-form-input id="input-lg-paySolution-merchantId" v-model="paySolutionMerchantId"
                placeholder="merchantId" />
            </b-form-group>
            <!-- authKey -->
            <b-form-group label-cols="2" label-cols-lg="2" label="authKey :" label-for="input-lg-paySolution-authKey">
              <b-form-input id="input-lg-paySolution-authKey" v-model="paySolutionAuthKey" placeholder="authKey" />
            </b-form-group>
            <b-button size="sm" variant="primary" @click="inputChange('paySolution')">
              <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
              <feather-icon icon="SaveIcon" size="12" />
            </b-button>
          </div>

        </b-card>
      </b-col>
      <!-- Pay Solution -->
      <b-col md="6">
        <b-card title="Omise">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Status :
            </b-card-text>
            <b-form-checkbox :checked="respData['omise'] != null
    ? respData['omise']['status']
    : false
    " class="custom-control-primary" name="check-button" switch @change="switchChange('omise.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["omise"] != null
      ? respData["omise"]["status"] == true
        ? "เปิด (Omise)"
        : "ปิด (Omise)"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>

          </b-row>
          <div v-if="respData['omise']['status']">
            <b-form-group label-cols="2" label-cols-lg="2" label="Public Key :" label-for="input-lg-omise-public-key">
              <b-form-input id="input-lg-omise-public-key" v-model="omisePublicKey" placeholder="omisePublicKey" />
            </b-form-group>
            <b-form-group label-cols="2" label-cols-lg="2" label="Secret Key :" label-for="input-lg-omise-secret-key">
              <b-form-input id="input-lg-omise-secret-key" v-model="omiseSecretKey" placeholder="omiseSecretKey" />
            </b-form-group>
            <b-button size="sm" variant="primary" @click="inputChange('omise')">
              <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
              <feather-icon icon="SaveIcon" size="12" />
            </b-button>
            <b-row class="mt-1">
              <b-card-text class="ml-2 mr-2">
                Activate :
              </b-card-text>
              <b-card-text class="ml-2">
                <b-badge :variant="respData['omise'] != null
    ? respData['omise']['activate'] == true
      ? 'success'
      : 'info'
    : 'dark'">
                  {{
    respData["omise"]
      != null
      ?
      respData['omise']['activate'] ==
        true
        ?
        "ยืนยันแล้ว"
        :
        "ยังไม่ยืนยัน"
      :
      "ยังไม่ได้ตั้งค่า"
  }}
                </b-badge>
                <b-button class="ml-2" size="sm" variant="primary">
                  <span class="mr-25 align-middle"> {{ $t("Activate") }}</span>
                  <feather-icon icon="CheckIcon" size="12" />
                </b-button>
              </b-card-text>

            </b-row>

          </div>

        </b-card>
      </b-col>
      <b-col v-if="respData['omise']['status']" md="6">
        <b-card title="Omise Setting">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Promptpay :
            </b-card-text>
            <b-form-checkbox :checked="respData['omise'] != null
    ? respData['omise']['promptpay']
    : false
    " class="custom-control-primary" name="check-button" switch @change="switchChange('omise.promptpay', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["omise"] != null
      ? respData["omise"]["promptpay"] == true
        ? "เปิด (Promptpay)"
        : "ปิด (Promptpay)"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>

          </b-row>

        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Payment">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Transfer :
            </b-card-text>
            <b-form-checkbox :checked="respData['payment'] != null
      ? respData['payment']['transfer']['status']
      : false
    " class="custom-control-primary" name="check-button" switch
              @change="switchChange('payment.transfer.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["payment"] != null
      ? respData["payment"]["transfer"]["status"] == true
        ? "เปิด (โอนจำนวนเต็ม)"
        : "ปิด (โอนจำนวนเต็ม)"
      : "ยังไม่ได้ตั้งค่า"
  }}
              <b-button v-if="respData['payment']['transfer']['status'] === true" size="sm" variant="primary"
                @click="settingPayment('transfer')">
                <span class="mr-25 align-middle"> {{ $t("Setting") }}</span>
              </b-button>
            </b-card-text>

          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              QRcode :
            </b-card-text>
            <b-form-checkbox :checked="respData['payment'] != null
    ? respData['payment']['qrcode']['status']
    : false
    " class="custom-control-primary" name="check-button" switch
              @change="switchChange('payment.qrcode.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["payment"] != null
      ? respData["payment"]["qrcode"]["status"] == true
        ? "เปิด (โอนผ่านQRcode)"
        : "ปิด (โอนผ่านQRcode)"
      : "ยังไม่ได้ตั้งค่า"
  }}
              <b-button v-if="respData['payment']['qrcode']['status'] === true" size="sm" variant="primary"
                @click="settingPayment('qrcode')">
                <span class="mr-25 align-middle"> {{ $t("Setting") }}</span>
              </b-button>
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Cash :
            </b-card-text>
            <b-form-checkbox :checked="respData['payment'] != null
    ? respData['payment']['cash']['status']
    : false
    " class="custom-control-primary" name="check-button" switch
              @change="switchChange('payment.cash.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["payment"] != null
      ? respData["payment"]["cash"]["status"] == true
        ? "เปิด (ชำระปลายทาง)"
        : "ปิด (ชำระปลายทาง)"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>

          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Payment">
          <!-- <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Promptpay :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['payment'] != null
                  ? respData['payment']['promptpay']['status']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('payment.promptpay.status', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["payment"] != null
                  ? respData["payment"]["promptpay"]["status"] == true
                    ? "เปิดระบบพร้อมเพย์"
                    : "ปิดระบบพร้อมเพย์"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row> -->
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Wallet :
            </b-card-text>
            <b-form-checkbox :checked="respData['payment'] != null
      ? respData['payment']['wallet']['status']
      : false
    " class="custom-control-primary" name="check-button" switch
              @change="switchChange('payment.wallet.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["payment"] != null
      ? respData["payment"]["wallet"]["status"] == true
        ? "เปิดระบบกระเป๋าเงิน"
        : "ปิดระบบกระเป๋าเงิน"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Point">
          <b-row>
            <b-card-text class="ml-2 mr-2">
              Status :
            </b-card-text>
            <b-form-checkbox :checked="respData['point'] != null
      ? respData['point']['status']
      : false
    " class="custom-control-primary" name="check-button" switch @change="switchChange('point.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["point"] != null
      ? respData["point"]["status"] == true
        ? "เปิดระบบคะแนน"
        : "ปิดระบบคะแนน"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>

          <b-form-group label-cols="2" label-cols-lg="2" label="Money paid :" label-for="input-lg-money_paid">
            <cleave id="input-lg-money_paid" v-model="money_paid" class="form-control" :raw="false"
              :options="{ numeral: true }" placeholder="money_paid" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Earned points :" label-for="input-lg-earned_points">
            <cleave id="input-lg-earned_points" v-model="earned_points" class="form-control" :raw="false"
              :options="{ numeral: true }" placeholder="earned_points" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('point')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Distance">
          <b-form-group label-cols="2" label-cols-lg="2" label="Money paid :" label-for="input-lg-max_distance">
            <cleave id="input-lg-max_distance" v-model="max_distance" class="form-control" :raw="false"
              :options="{ numeral: true }" placeholder="max_distance" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('distance')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="autoMessageContactUs">
          <b-form-group label-cols="2" label-cols-lg="2" label="Message :" label-for="input-lg-autoMessageContactUs">
            <b-form-input id="input-lg-autoMessageContactUs" v-model="autoMessageContactUs"
              placeholder="autoMessageContactUs" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('autoMessageContactUs')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="Notification">
          <b-form-group label-cols="2" label-cols-lg="2" label="Key :" label-for="input-lg-notification-key">
            <b-form-input id="input-lg-notification-key" v-model="notificationKey" placeholder="Key" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Serverkey :"
            label-for="input-lg-notification-serverkey">
            <b-form-input id="input-lg-notification-serverkey" v-model="notificationServerkey"
              placeholder="Serverkey" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('notification')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="otpSetting">
          <b-form-group label-cols="2" label-cols-lg="2" label="Type :" label-for="input-lg-type">
            <v-select id="input-lg-type" v-model="otpSetting.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name" :options="OptionTypeOTP" :reduce="(OptionTypeOTP) => OptionTypeOTP.value"
              class="select-size-lg" />
          </b-form-group>
          <div v-if="otpSetting.type === 'thaibulksms'">
            <b-form-group label-cols="2" label-cols-lg="2" label="Username :" label-for="input-lg-username">
              <b-form-input id="input-lg-username" v-model="otpSetting.username" placeholder="Username" />
            </b-form-group>
            <b-form-group label-cols="2" label-cols-lg="2" label="Password :" label-for="input-lg-password">
              <b-form-input id="input-lg-password" v-model="otpSetting.password" placeholder="Password" />
            </b-form-group>
            <b-form-group label-cols="2" label-cols-lg="2" label="ExpireDate :" label-for="input-lg-expireDate">
              <b-form-input id="input-lg-expireDate" v-model="otpSetting.expireDate" placeholder="ExpireDate" />
            </b-form-group>
            <b-form-group label-cols="2" label-cols-lg="2" label="smsAmount :" label-for="input-lg-smsAmount">
              <b-form-input id="input-lg-smsAmount" v-model="otpSetting.smsAmount" placeholder="smsAmount" />
            </b-form-group>
            <b-form-tags v-model="otpSetting.message" no-outer-focus>
              <template #default="{
    tags,
    inputAttrs,
    inputHandlers,
    addTag,
    removeTag,
  }">
                <b-card-text class="mr-2">
                  Message :
                </b-card-text>
                <span v-for="(otp, index) in otpSetting.message" :key="index">{{
    otp
  }}</span>
                <br>
                <b-row>
                  <b-button size="sm" variant="primary" class="ml-2 mr-2">
                    <span class="mr-25 align-middle"> OTP </span>
                  </b-button>
                  <b-button size="sm" variant="primary" class="mr-2">
                    <span class="mr-25 align-middle"> REF </span>
                  </b-button>
                  <b-button size="sm" variant="primary" class="mr-2">
                    <span class="mr-25 align-middle"> MINUTE </span>
                  </b-button>
                </b-row>

                <b-input-group aria-controls="my-custom-tags-list">
                  <input v-bind="inputAttrs" placeholder="เพิ่มข้อความใหม่" class="form-control" v-on="inputHandlers">
                  <b-input-group-append>
                    <b-button variant="primary" @click="addTag()">
                      {{ $t("Add") }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-col cols="12">
                  <draggable v-model="otpSetting.message" tag="ul" class="list-group cursor-move">
                    <b-list-group-item v-for="(tag, index) in tags" :key="index" tag="li">
                      <div class="d-flex">
                        <!-- <b-avatar :text="listItem.name.slice(0,2)" /> -->
                        <div class="ml-25">
                          <b-card-text class="mb-0">
                            {{ tag }}
                          </b-card-text>
                          <b-button variant="link" size="sm" :aria-controls="`my-custom-tags-tag_${tag.replace(
    /\s/g,
    '_'
  )}_`" class="py-0" @click="removeTag(tag)">
                            {{ $t("Delete") }}
                          </b-button>
                        </div>
                      </div>
                    </b-list-group-item>
                    <!-- <b-row
                    v-for="(tag,index) in tags"
                    :key="index"
                  >
                    <b-card
                      :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                      tag="li"
                      class="shadow-none border mt-1 mr-1 ml-1 mb-0"
                    >
                      <strong>{{ "[ "+ (index+1) + " ]" }} {{ tag }}</strong>
                      <b-button
                        variant="link"
                        size="sm"
                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        class="py-0"
                        @click="removeTag(tag)"
                      >
                        {{ $t('Delete') }}
                      </b-button>
                    </b-card>
                  </b-row> -->
                  </draggable>
                </b-col>
              </template>
            </b-form-tags>
          </div>
          <b-button size="sm" variant="primary" @click="inputChange('otpSetting')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <!-- สำหรับแจ้งออเดอร์ -->
        <b-card title="Line Notify For New Order">
          <b-row class="mt-1">
            <b-card-text class="ml-1">
              Verify :
            </b-card-text>
            <b-card-text class="ml-2">
              {{
    respData["line"] != null
      ? respData["line"]["verify"] == true
        ? "ยืนยันแล้ว"
        : "ยังไม่ได้รับการยืนยัน"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-form-group label-cols="2" label-cols-lg="2" label="Key :" label-for="input-lg-line-key">
            <b-form-input id="input-lg-line-key" v-model="lineKey" placeholder="Key" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('line')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
        <!-- สำหรับแจ้งออเดอร์ -->

        <!-- สำหรับแจ้งแชท -->
        <b-card title="Line Notify For Chat">
          <b-row class="mt-1">
            <b-card-text class="ml-1">
              Verify :
            </b-card-text>
            <b-card-text class="ml-2">
              {{
    respData["lineChat"] != null
      ? respData["lineChat"]["verify"] == true
        ? "ยืนยันแล้ว"
        : "ยังไม่ได้รับการยืนยัน"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-form-group label-cols="2" label-cols-lg="2" label="Key :" label-for="input-lg-line-key">
            <b-form-input id="input-lg-line-key" v-model="lineChatKey" placeholder="Key" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('lineChat')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
        <!-- สำหรับแจ้งแชท -->
      </b-col>
      <b-col md="12">
        <b-card title="Privacy Detail">
          <b-form-group label-cols="2" label-cols-lg="2" label="Terms :" label-for="input-lg-Privacy-detail-Terms">
            <b-form-input id="input-lg-Privacy-detail-Terms" v-model="privacyDetailTerms" placeholder="Terms" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Privacy :" label-for="input-lg-Privacy-detail-Privacy">
            <b-form-input id="input-lg-Privacy-detail-Privacy" v-model="privacyDetailPrivacy" placeholder="Privacy" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('privacyDetail')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Show Components">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Icon Topic Home :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['iconTopicHome']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.iconTopicHome.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['iconTopicHome']['status'] == true
        ? "เปิดแสดงไอคอนหน้าเมนู"
        : "ปิดแสดงไอคอนหน้าเมนู"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Login Page When Start :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['loginPageWhenStart']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.loginPageWhenStart.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['loginPageWhenStart']['status'] == true
        ? "เปิดหน้าเข้าสู่ระบบเมื่อเปิดแอป"
        : "เปิดหน้าหลักเมื่อเปิดแอป"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Use Location :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['useLocation']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.useLocation.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['useLocation']['status'] == true
        ? "เปิดใช้งานเพิ่ม จังหวัด อำเภอ ตำบลของโปรไฟล์"
        : "ปิดใช้งานเพิ่ม จังหวัด อำเภอ ตำบลของโปรไฟล์"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Popup Alert Address :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['popupAlertAddress']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.popupAlertAddress.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['popupAlertAddress']['status'] == true
        ? "เปิดใช้งานสอบถามจังหวัด อำเภอ ตำบล"
        : "ปิดใช้งานสอบถามจังหวัด อำเภอ ตำบล"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Filter Search Home :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['filterSearchHome']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.filterSearchHome.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['filterSearchHome']['status'] == true
        ? "เปิดใช้งานตัวกรองการค้นหาในหน้าแรก"
        : "ปิดใช้งานตัวกรองการค้นหาในหน้าแรก"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Service Type Home (Category):
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['serviceTypeHome']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.serviceTypeHome.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['serviceTypeHome']['status'] == true
        ? "เปิดใช้งานแสดงประเภทบริการหน้าแรก"
        : "ปิดใช้งานแสดงประเภทบริการหน้าแรก"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Last View Home :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['lastViewHome']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.lastViewHome.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['lastViewHome']['status'] == true
        ? "เปิดใช้งานแสดงรายการล่าสุดหน้าแรก"
        : "ปิดใช้งานแสดงรายการล่าสุดหน้าแรก"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              PDPA :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['pdpa']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.pdpa.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['pdpa']['status'] == true
        ? "เปิดใช้งาน PDPA"
        : "ปิดใช้งาน PDPA"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Question :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['questionForOrder']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.questionForOrder.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['questionForOrder']['status'] == true
        ? "เปิดใช้งานคำถามก่อนการจอง"
        : "ปิดใช้งานคำถามก่อนการจอง"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Use Only Partner :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['useOnlyPartner']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.useOnlyPartner.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['useOnlyPartner']['status'] == true
        ? "เปิดใช้งานใช้แค่ partner"
        : "ปิดใช้งานใช้แค่ partner"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Force Login :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['forceLogin']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.forceLogin.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['forceLogin']['status'] == true
        ? "เปิดใช้งานบังคับ login"
        : "ปิดใช้งานบังคับ login"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Border Icon Service Type :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['borderIconServiceType']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.borderIconServiceType.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['borderIconServiceType']['status'] == true
        ? "เปิดใช้งานกรอบไอคอนบริการ"
        : "ปิดใช้งานกรอบไอคอนบริการ"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Layout Expertise :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['layoutExpertise']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.layoutExpertise.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['layoutExpertise']['status'] == true
        ? "เปิดใช้งานความเชี่ยวชาญ"
        : "ปิดใช้งานความเชี่ยวชาญ"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Hide Card Topic HomePage :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['hideCardTopicHomePage']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.hideCardTopicHomePage.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['hideCardTopicHomePage']['status'] == false
        ? "เปิดใช้งานการ์ดหน้าแรก"
        : "ปิดใช้งานการ์ดหน้าแรก"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Hide Remove User Button :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['hideRemoveUser']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.hideRemoveUser.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['hideRemoveUser']['status'] == false
        ? "เปิดใช้งานปุ่มขอลบผู้ใช้งาน"
        : "ปิดใช้งานปุ่มขอลบผู้ใช้งาน"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Layout ready to serve :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['layoutReadyToServe']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.layoutReadyToServe.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['layoutReadyToServe']['status'] == true
        ? "เปลี่ยน Layout ready to serve"
        : "ดั้งเดิม"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Outside Work :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['outsideWork']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.outsideWork.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['outsideWork']['status'] == true
        ? "เปิดการใช้งาน งานนอก"
        : "ปิดการใช้งาน งานนอก"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Soical Login :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['socialLogin']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.socialLogin.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['socialLogin']['status'] == true
        ? "เปิดการใช้งาน"
        : "ปิดการใช้งาน"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Apple Login :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['appleLogin']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.appleLogin.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['appleLogin']['status'] == true
        ? "เปิดการใช้งาน"
        : "ปิดการใช้งาน"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Facebook Login :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['facebookLogin']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.facebookLogin.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['facebookLogin']['status'] == true
        ? "เปิดการใช้งาน"
        : "ปิดการใช้งาน"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Gmail Login :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['gmailLogin']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.gmailLogin.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['gmailLogin']['status'] == true
        ? "เปิดการใช้งาน"
        : "ปิดการใช้งาน"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Hode Chat User To Shop :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['showComponents'] != null ? respData['showComponents']['hideChatUserToShop']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('showComponents.hideChatUserToShop.status', $event)" />
            <b-card-text class="ml-2">
              {{
    respData["showComponents"] != null
      ? respData['showComponents']['hideChatUserToShop']['status'] == true
        ? "เปิดการใช้งาน"
        : "ปิดการใช้งาน"
      : "ยังไม่ได้ตั้งค่า"
  }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Setting Components">
          <b-form-group label-cols="2" label-cols-lg="2" label="Category Length :" label-for="input-lg-categoryLength">
            <cleave id="input-lg-categoryLength" v-model="categoryLength" class="form-control" :raw="false"
              :options="{ numeral: true }" placeholder="categoryLength" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="PriceRange :" label-for="input-lg-priceRange">
            <cleave id="input-lg-priceRange" v-model="priceRange.start" class="form-control mb-1"
              :options="{ numeral: true }" placeholder="Start" />
            <cleave id="input-lg-priceRange" v-model="priceRange.end" class="form-control" :options="{ numeral: true }"
              placeholder="End" />
          </b-form-group>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Default Search Result Display :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['settingComponents'] != null ? respData['settingComponents']['defaultSearchResultDisplay']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('settingComponents.defaultSearchResultDisplay.status', $event)" />
            <b-card-text class="ml-2">
              {{
              respData["settingComponents"] != null
              ? respData['settingComponents']['defaultSearchResultDisplay']['status'] == true
              ? "ร้านค้า"
              : "บริการ"
              : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-form-group label-cols="2" label-cols-lg="2" label="Terms and Conditions of Use :"
            label-for="input-terms-and-conditions-of-use">
            <b-form-textarea id="input-terms-and-conditions-of-use" v-model="terms_and_conditions_of_use"
              class="form-control" placeholder="Terms and Conditions of Use" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Terms of Use :" label-for="input-terms-of-use">
            <b-form-textarea id="input-terms-of-use" v-model="terms_of_use" class="form-control"
              placeholder="Terms of Use" />
          </b-form-group>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Multiple Upload Image :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['settingComponents'] != null ? respData['settingComponents']['multiple_upload_image']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('settingComponents.multiple_upload_image.status', $event)" />
            <b-card-text class="ml-2">
              {{
              respData["settingComponents"] != null
              ? respData['settingComponents']['multiple_upload_image']['status'] == true
              ? "เปิดการใช้งานอัปโหลดหลายรูปภาพ"
              : "ปิดการใช้งานอัปโหลดหลายรูปภาพ"
              : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-button size="sm" variant="primary" @click="inputChange('settingComponents')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="System flow">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Review Before :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['systemFlow'] != null ? respData['systemFlow']['reviewBefore']['status'] : false"
              class="custom-control-primary" name="check-button" switch
              @change="switchChange('systemFlow.reviewBefore.status', $event)" />
            <b-card-text class="ml-2">
              {{
              respData["systemFlow"] != null
              ? respData['systemFlow']['reviewBefore']['status'] == true
              ? "รีวิวก่อนซื้อ"
              : "รีวิวหลังซื้อ"
              : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="FontFamily">
          <b-form-group label-cols="2" label-cols-lg="2" label="Type :" label-for="input-lg-type">
            <v-select id="input-lg-type" v-model="fontFamily" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name" :options="OptionFontFamily" :reduce="(OptionFontFamily) => OptionFontFamily.value"
              class="select-size-lg" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('fontFamily')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="Mobile Color">
          <b-form-group label-cols="2" label-cols-lg="2" label="Primary Color :" label-for="input-lg-primaryColor">
            <b-form-input id="input-lg-primaryColor" v-model="primaryColor" placeholder="Primary Color" type="color" />
            <b-form-input id="input-lg-primaryColor" v-model="primaryColor" placeholder="Primary Color" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Secondary Color :" label-for="input-lg-secondaryColor">
            <b-form-input id="input-lg-secondaryColor" v-model="secondaryColor" placeholder="Secondary Color"
              type="color" />
            <b-form-input id="input-lg-secondaryColor" v-model="secondaryColor" placeholder="Secondary Color" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Button Color :" label-for="input-lg-buttonColor">
            <b-form-input id="input-lg-buttonColor" v-model="buttonColor" placeholder="Button Color" type="color" />
            <b-form-input id="input-lg-buttonColor" v-model="buttonColor" placeholder="Button Color" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Errorbtn Color :" label-for="input-lg-errorbtnColor">
            <b-form-input id="input-lg-errorbtnColor" v-model="errorbtnColor" placeholder="Errorbtn Color"
              type="color" />
            <b-form-input id="input-lg-errorbtnColor" v-model="errorbtnColor" placeholder="Errorbtn Color" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Loading Color :" label-for="input-lg-loadingColor">
            <b-form-input id="input-lg-loadingColor" v-model="loadingColor" placeholder="Loading Color" type="color" />
            <b-form-input id="input-lg-loadingColor" v-model="loadingColor" placeholder="Loading Color" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Gradient Color :" label-for="input-lg-gradient">
            <b-row>
              <b-col md="4">
                <span> Gradient dark Color </span>
                <b-form-input id="input-lg-gradientDark" v-model="gradientDark" placeholder="Gradient dark Color"
                  type="color" />
                <b-form-input id="input-lg-gradientDark" v-model="gradientDark" placeholder="Gradient dark Color" />
              </b-col>
              <b-col md="4">
                <span> Gradient neutral Color </span>
                <b-form-input id="input-lg-gradientNeutral" v-model="gradientNeutral"
                  placeholder="Gradient neutral Color" type="color" />
                <b-form-input id="input-lg-gradientNeutral" v-model="gradientNeutral"
                  placeholder="Gradient neutral Color" />
              </b-col>
              <b-col md="4">
                <span> Gradient bright Color </span>
                <b-form-input id="input-lg-gradientBright" v-model="gradientBright" placeholder="Gradient bright Color"
                  type="color" />
                <b-form-input id="input-lg-gradientBright" v-model="gradientBright"
                  placeholder="Gradient bright Color" />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Title Color :" label-for="input-lg-titleColor">
            <b-form-input id="input-lg-titleColor" v-model="titleColor" placeholder="Title Color" type="color" />
            <b-form-input id="input-lg-titleColor" v-model="titleColor" placeholder="Title Color" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Background Color :"
            label-for="input-lg-backgroundColor">
            <b-form-input id="input-lg-backgroundColor" v-model="backgroundColor" placeholder="background Color"
              type="color" />
            <b-form-input id="input-lg-backgroundColor" v-model="backgroundColor" placeholder="background Color" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('color')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge,
  BFormTags,
  BListGroupItem,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import StoreModule from '@/store/services/hero/applicationSettings'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import Cleave from 'vue-cleave-component'

const STORE_MODULE_NAME = 'applicationSettings'

export default {
  components: {
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BBadge,
    draggable,
    BInputGroup,
    BInputGroupAppend,
    BFormTags,
    BListGroupItem,
    BButton,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // otpSetting
      otpSetting: {
        type: 'none',
        username: '',
        password: '',
        expireDate: 0,
        smsAmount: 0,
        message: [
          'เพื่อการยืนยันรหัส ป้อนรหัสยืนยัน ',
          '$OTP_CODE',
          ' รหัสอ้างอิง #',
          '$REF',
          ' มีอายุการใช้งาน ',
          '$MINUTE',
          ' นาที',
        ],
      },
      OptionTypeOTP: [
        { name: 'None', value: 'none' },
        { name: 'Firebase', value: 'firebase' },
        { name: 'Thaibulksms', value: 'thaibulksms' },
      ],
      fontFamily: 'K2D',
      OptionFontFamily: [
        { name: 'Device', value: 'default' },
        { name: 'K2D', value: 'K2D' },
        { name: 'Kanit', value: 'Kanit' },
        { name: 'Prompt', value: 'Prompt' },
        { name: 'Sarabun', value: 'Sarabun' },
        { name: 'Noto Sans Thai', value: 'Noto Sans Thai' },
        { name: 'Itim', value: 'Itim' },
        { name: 'Mitr', value: 'Mitr' },
        { name: 'Bai Jamjuree', value: 'Bai Jamjuree' },
        { name: 'Krub', value: 'Krub' },
        { name: 'Pridi', value: 'Pridi' },
        { name: 'IBM Plex Sans Thai', value: 'IBM Plex Sans Thai' },
        { name: 'IBM Plex Sans Thai Looped', value: 'IBM Plex Sans Thai Looped' },
        { name: 'Noto Sans Thai Looped', value: 'Noto Sans Thai Looped' },
      ],
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData
    },
    // Pay Solution
    paySolutionSecretKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.payment.paySolution.secretKey
      },
      set(newValue) {
        const obj = {
          name: 'payment.paySolution.secretKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    paySolutionApiKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.payment.paySolution.apiKey
      },
      set(newValue) {
        const obj = {
          name: 'payment.paySolution.apiKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    paySolutionMerchantId: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.payment.paySolution.merchantId
      },
      set(newValue) {
        const obj = {
          name: 'payment.paySolution.merchantId',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    paySolutionAuthKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.payment.paySolution.authKey
      },
      set(newValue) {
        const obj = {
          name: 'payment.paySolution.authKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    // Pay Solution
    omisePublicKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.omise.publicKey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'omise.publicKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    omiseSecretKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.omise.secretKey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'omise.secretKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    autoMessageContactUs: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.autoMessageContactUs
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'autoMessageContactUs',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    notificationKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.notification.key
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'notification.key',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    notificationServerkey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.notification.serverkey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'notification.serverkey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    lineKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.line.key
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'line.key',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    lineChatKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.lineChat.key
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'lineChat.key',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    privacyDetailTerms: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.privacyDetail.terms
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'privacyDetail.terms',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    privacyDetailPrivacy: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.privacyDetail.privacy
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'privacyDetail.privacy',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    categoryLength: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.settingComponents.categoryLength
      },
      // setter
      set(newValue) {
        console.log(newValue)
        const obj = {
          name: 'settingComponents.categoryLength',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    priceRange: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.settingComponents.priceRange
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'settingComponents.priceRange',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    defaultSearchResultDisplay: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.settingComponents.defaultSearchResultDisplay
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'settingComponents.defaultSearchResultDisplay',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    terms_and_conditions_of_use: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.settingComponents.terms_and_conditions_of_use
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'settingComponents.terms_and_conditions_of_use',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    terms_of_use: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.settingComponents.terms_of_use
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'settingComponents.terms_of_use',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    money_paid: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.point.money_paid
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'point.money_paid',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    earned_points: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.point.earned_points
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'point.earned_points',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    max_distance: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.distance.max_distance
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'distance.max_distance',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    primaryColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.primaryColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.primaryColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    secondaryColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.secondaryColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.secondaryColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.buttonColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    errorbtnColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.errorbtnColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.errorbtnColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    loadingColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.loadingColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.loadingColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    gradientDark: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.gradient.dark
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.gradient.dark',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    gradientNeutral: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.gradient.neutral
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.gradient.neutral',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    gradientBright: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.gradient.bright
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.gradient.bright',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    titleColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.titleColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.titleColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    backgroundColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.backgroundColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.backgroundColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/get`)
      .then(result => {
        console.log('fetch Success : ', result)
        if (result.data.data.otp != null) {
          this.otpSetting.type = result.data.data.otp.type
          this.otpSetting.username = result.data.data.otp.username
          this.otpSetting.password = result.data.data.otp.password
          this.otpSetting.message = result.data.data.otp.message
          this.otpSetting.expireDate = result.data.data.otp.expireDate
          this.otpSetting.smsAmount = result.data.data.otp.smsAmount
        }
        if (result.data.data.fontFamily != null) {
          this.fontFamily = result.data.data.fontFamily
        }
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    settingPayment(data) {
      this.$router.push({ path: `/e-commerce/application/settings/payment/${data}` })
    },
    inputChange(name) {
      const obj = {}
      console.log(name)
      if (name === 'color') {
        obj.color = {
          primaryColor: this.primaryColor,
          secondaryColor: this.secondaryColor,
          buttonColor: this.buttonColor,
          errorbtnColor: this.errorbtnColor,
          loadingColor: this.loadingColor,
          gradient: {
            dark: this.gradientDark,
            neutral: this.gradientNeutral,
            bright: this.gradientBright,
          },
          titleColor: this.titleColor,
          backgroundColor: this.backgroundColor,
        }
      } else if (name === 'otpSetting') {
        obj.otp = this.otpSetting
      } else if (name === 'line') {
        obj.line = {
          key: this.lineKey,
        }
      } else if (name === 'lineChat') {
        obj.lineChat = {
          key: this.lineChatKey,
        }
      } else if (name === 'privacyDetail') {
        obj.privacyDetail = {
          terms: this.privacyDetailTerms,
          privacy: this.privacyDetailPrivacy,
        }
      } else if (name === 'notification') {
        obj.notification = {
          key: this.notificationKey,
          serverkey: this.notificationServerkey,
        }
      } else if (name === 'autoMessageContactUs') {
        obj.autoMessageContactUs = this.autoMessageContactUs
      } else if (name === 'omise') {
        obj.omise = {
          publicKey: this.omisePublicKey,
          secretKey: this.omiseSecretKey,
        }
      } else if (name === 'fontFamily') {
        obj.fontFamily = this.fontFamily
      } else if (name === 'settingComponents') {
        console.log(this.priceRange)
        obj.settingComponents = {
          categoryLength: this.categoryLength,
          priceRange: this.priceRange,
          defaultSearchResultDisplay: this.defaultSearchResultDisplay,
          terms_and_conditions_of_use: this.terms_and_conditions_of_use,
          terms_of_use: this.terms_of_use,
        }
      } else if (name === 'point') {
        obj.point = {
          money_paid: this.money_paid,
          earned_points: this.earned_points,
        }
      } else if (name === 'distance') {
        obj.distance = {
          max_distance: this.max_distance.replaceAll(',', ''),
        }
      } else if (name === 'paySolution') {
        obj.paySolution = {
          secretKey: this.paySolutionSecretKey,
          apiKey: this.paySolutionApiKey,
          merchantId: this.paySolutionMerchantId,
          authKey: this.paySolutionAuthKey,
        }
      }

      store
        .dispatch(`${STORE_MODULE_NAME}/inputChange`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    switchChange(name, status) {
      console.log(name, status)
      const obj = {
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
